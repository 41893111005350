import { navigate } from "gatsby";
import { useDashboardChangePassword } from "hooks/Forms/useDashboardChangePassword";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import formCommonPostData from "utils/form-common-post-data";

const ResetPasswordDashboard = () => {
  const [urlParam, setUrlParam] = useState("");
  const [emailParam, setEmailParam] = useState("");
  const [formStatus, setFormStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorToken, setErrorToken] = useState(false);
  const { mutateAsync } = useDashboardChangePassword();
  const [newPassword, setNewPassword] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var token = url.searchParams.get("token");
    var email = url.searchParams.get("email");
    if (token === "" || email === "") {
      return navigate("/");
    }
    setUrlParam(token);
    setEmailParam(email);
  }, []);

  const onSubmit = (data) => {
    //  console.log(data);
    setErrorStatus(false);
    setErrorToken(false);
    setFormStatus(false);

    if (data.new_Password !== data.confirmPassword) {
      setErrorStatus(true);
      return false;
    }
    const postData1 = {
      old_password: data.old_password,
      password: data.confirmPassword,
    };
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((response) => {
      // console.log(response);
      if (response.status === 1) {
        reset();
        setFormStatus(true);
        return navigate("/");
      }
      if (response.message === "Invalid Token") {
        setErrorToken(true);
      }
      //  else {
      //   setErrorStatus(true);
      // }
    });
  };



  return (
    <div className="w-4/12 mx-auto ">
      <div className="text-new text-white py-4">
        Reset your password here
      </div>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="password"
          name="old_password"
          id="old_password"
          placeholder="Current Password"
          autoComplete="off"
          className="px-2 py-2 bg-white rounded-sm w-full text-black"
          {...register("old_password", {
            required: true,
          })}
        />
        {errors.old_password && (
          <span className="text-red py-2 block">
            Please enter your current password.
          </span>
        )}
        <input
          type="password"
          name="new_Password"
          id="Password"
          placeholder="New Password"
          autoComplete="off"
          className="px-2 py-2 bg-white rounded-sm w-full mt-5 text-black"
          {...register("new_Password", {
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
          })}
        />
        {errors.new_Password && (
          <span className="text-red py-2 block">
            Password should contain one Capital Letter, one Small Letter, and
            the number of characters should be between 6 to 15.
          </span>
        )}
        <input
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          placeholder="Confirm Password"
          autoComplete="off"
          className="px-2 py-2 bg-white rounded-sm w-full my-5 text-black"
          {...register("confirmPassword", {
            required: true,
          })}
        />
        {formStatus ? (
          <span className="text-[#96D149] text-sm">
            Password Changed! <br />
          </span>
        ) : (
          <></>
        )}
        {errorStatus ? (
          <span className="text-red text-sm">
            Password and confirm password should match! <br />
          </span>
        ) : (
          <></>
        )}
        {errorToken ? (
          <span className="text-red text-sm py-2 block">
            TOKEN EXPIRED, Request password again <br />
          </span>
        ) : (
          <></>
        )}
        <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        <button className="bg-[#2664E4] py-2 flex justify-center items-center rounded-sm w-full mb-1">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordDashboard;

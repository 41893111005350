import UserLayout from "components/userAccount/layout";
import ResetPasswordDashboard from "components/userAccount/resetPasswordDashboard";
import React from "react";
import Seo from "components/seo";

function index() {
  return (
    <UserLayout>
      <Seo title="Change Password - Dashboard" />
      <ResetPasswordDashboard />      
    </UserLayout>
  );
}

export default index;
